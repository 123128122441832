import { Component, OnInit, OnDestroy } from '@angular/core';

import { AuthService } from '../services/auth.service';

import {HttpHeaders, HttpClient } from '@angular/common/http';


import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';

export interface DialogData {
  animal: string;
}

import { AdduserDialogComponent } from '../adduser-dialog/adduser-dialog.component';
import { EdituserDialogComponent } from '../edituser-dialog/edituser-dialog.component';
import {Clipboard} from '@angular/cdk/clipboard';

import { interval, Subscription } from 'rxjs';

import { environment } from '../../environments/environment';


const source = interval(5000);

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.css']
})
export class ManageUserComponent implements OnDestroy {


  apiURL = environment.apiUrl;
   lat = 28.4595;
   lng = 77.0266;
  //lat = 28.4183269;
  //lng = 77.1010208;
  marker_result:any;
  result:any;
  name: any;
  trackingid: any;
  created_at: any;
  mobile: any;
  share: any;
  kid_id: any;
  plan_id:any;
  arr = [] as any;
  spinner: boolean;
  map_spinner: boolean;
  fence =  {}
  all_fence= {}
  is_fence: string;
  status: string;
  last_seen: any;
    url  = 'https://spoorit.evoxyz.tech/assets/marker.svg';
  
  subscription: Subscription;

  
marker_data = [] as any;
position = [] as any;
new_position = [] as any;


     i = 0;
     deltaLat: number;
     deltaLng: number;
     count = 0;
     transition_count = 1;

constructor(private breakpointObserver: BreakpointObserver,public dialog: MatDialog,private _snackBar: MatSnackBar,public authService: AuthService, private http:HttpClient,private clipboard: Clipboard) {

     

this.subscription = source.subscribe(
  val => {
    this.setCurrentLocation(false);
  }
  );

 }

   
     transition(new_position, j) {
      //console.log("Old position " + this.position + " New " + this.new_position + " " + j);
   //   console.log("Old position " + this.position.length + " New " + this.new_position.length + " " + j);
       
        this.deltaLat = (this.new_position[j][0] - this.position[j][0])/5;
        this.deltaLng = (this.new_position[j][1] - this.position[j][1])/5;
        this.moveMarker(this.position,j);
        //console.log(" position length " + this.position.length + " transition count " + this.transition_count)
         if (this.position.length == this.transition_count) {
          console.log(this.position)
          console.log(this.new_position)

        this.position = this.new_position;
        this.new_position = [];
        this.transition_count = 1;
      } else {
      this.transition_count++;
      }
    }
    
     moveMarker(position,j){
            //console.log("position before" + this.position);

        this.position[j][0] += this.deltaLat;
        this.position[j][1] += this.deltaLng;
              //console.log("position later" + this.position);

             // this.marker_data.length = 0
        this.marker_data.push({lat: this.position[j][0],lng:this.position[j][1]})
       // console.log(this.marker_data)

        if(this.i!=5){
            this.i++;
            setTimeout(() => this.moveMarker(this.position,j), 500);
        }
    }
    
    
  
  ngOnDestroy(): void {
      this.subscription.unsubscribe();
   }
  ngOnInit(): void {
      this.authService.IsAuthenticated = true;
      this.setCurrentLocation(false);
      this.getUsers();

  }




 horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    animal: string;


  // Get Current Location Coordinates
   setCurrentLocation(e) {
    if (e) {
 this.map_spinner = true;
}

    const headers = new HttpHeaders()
    .set("Accept", "application/json");
    this.http.get(this.apiURL+"fetch_location.php",
      {headers})
    .subscribe(
      val => {
        if (this.map_spinner) {
         this.map_spinner = false;
      }

        this.marker_result = val;
        this.result = JSON.stringify(val, null, 4);
       // console.log(this.result);
        var datas = JSON.parse(this.result);
       // console.log(datas);
       if(datas.length > 0) {

                this.lat = datas[0]['lat'];
                this.lng = datas[0]['lng'];
       }
     //          this.marker_data.length = 0
     //    for (var j = 0; j<datas.length;j++) {
     //      var latitude = datas[j]['lat'];
     //      var longitude = datas[j]['lng'];
     // // this.marker_data.push({lat:latitude,lng:longitude})
     //    if (this.count == 0) {
     //      //console.log("Pos " + typeof this.position + " " + j + " " + latitude + " " + longitude + " " + this.position);
     //      if (!this.position[j]) this.position[j] = []
     //        this.position[j][0] = latitude;
     //        this.position[j][1] = longitude; 
     //        this.marker_data.push({lat:latitude,lng:longitude})

     //      }
          
     //      if (this.count > 0) {
     //        if (!this.new_position[j]) this.new_position[j] = []

     //        this.new_position[j][0] = latitude;
     //        this.new_position[j][1] = longitude;
     //        this.transition(this.new_position, j);

     //      }
     //                    }

     //    console.log("count " + this.count)
     //   // console.log("marker " + JSON.stringify(this.marker_data))
     //    this.count++;

      },
      response => {
        //console.log("Get call in error", response);
      },
      () => {
        //console.log("The Get observable is now completed.");
      }
      );

      }

  getUsers( ) {

this.spinner = true;
        const headers = new HttpHeaders()
    .set("Accept", "application/json");
    this.http.get(this.apiURL+"users_new.php",
      {headers})
    .subscribe(
      val => {
         let arra = [];  
    Object.keys(val).map(function(key){  
      arra.push({[key]:val[key]})  
      return arra;  
    });   

    if (arra[0]['status'] === 0) {
      this.spinner = false;
        this.result = JSON.stringify(val, null, 4);
        //console.log(this.result);
        var result_data = JSON.parse(JSON.stringify(val));
        this.plan_id = result_data.plan_id;
        let arr = [];
                this.arr.length = 0

            for (var i = 0; i<result_data.response_data.data.length;i++) {

        this.name = result_data.response_data.data[i]['name'];
        this.mobile = result_data.response_data.data[i]['mobile'];
        this.created_at = result_data.response_data.data[i]['created'];
        this.trackingid = result_data.response_data.data[i]['trackingid'];
        this.share = result_data.response_data.data[i]['share'];
        this.fence = result_data.response_data.data[i]['geo_fence'];
        this.all_fence = result_data.response_data.data[i]['all_fences'];
        this.kid_id =  result_data.response_data.data[i]['kidid'];
        this.status =  result_data.response_data.data[i]['status'];
        this.last_seen =  result_data.response_data.data[i]['last_seen'];
      
      //console.log(" Fence " + this.fence + " length " + JSON.stringify(this.fence).length );
        if (this.fence) {
            this.is_fence = "Yes"
          } else {
            this.is_fence  = "No" 
        }

        this.arr.push({tracking_id: this.trackingid,mobile:this.mobile,name:this.name,share:this.share,created_at:this.created_at,fence:this.fence,all_fence:this.all_fence,kid_id:this.kid_id,is_fence:this.is_fence,kid_fence:this.fence,live_status:this.status,last_seen:this.last_seen});
        //arr.push({trackingid: this.trackingid});
    
    }
           

  }

      },
      response => {
        this.spinner = false;
         this._snackBar.open("Please refresh, if problem persist speak to SpoorIT Administrator!", 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
      },
      () => {
      //  console.log("The Get observable is now completed.");
      }
      );

      }

      openUser() {

      const dialogRef = this.dialog.open(AdduserDialogComponent, {
        width: '250px',
        data: { animal: this.animal}
      });

      dialogRef.afterClosed().subscribe(result => {
        this.animal = result;

      if(this.animal) {        
        this.httpPostExample(this.animal);
      }
      });    
    }

    editUser(value,name,id,mobile,fence) {
      //console.log("Edit User " + value);
           const dialogRef = this.dialog.open(EdituserDialogComponent, {
        width: '600px',
        data: { fence: value,
                kid_name: name,
                kid_id: id,
                kid_mobile: mobile,
                kid_fence: fence,
                admin_planid: this.plan_id
               }
      });
              dialogRef.afterClosed().subscribe(result => {
                      this.getUsers();

              });
    }

    upGrade() {
                this._snackBar.open('Please upgrade to paid plan for this feature.', 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
    }


    httpPostExample(username) {
      this.spinner = true;
      const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
      const user_id = this.authService.getUserID();
      this.http.post(this.apiURL+"add_kid.php",
      {
        "name": username,
        "user_id": user_id
      },
      {headers})
      .subscribe(
        val => {
        
          let arr = [];  
          Object.keys(val).map(function(key){  
            arr.push({[key]:val[key]})  
            return arr;  
          });   
          if (arr[0]['status'] === 1) {
            this.spinner = false;      

            this._snackBar.open(arr[1]['response']['message'], 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
          } else if (arr[0]['status'] === 0) {
            this._snackBar.open(arr[1]['response']['message'], 'Close', {
              duration: 7000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['success-snackbar']

            });
            setTimeout(function(){
            this.spinner = false;      
             window.location.reload();
         }, 7000);
          }
        },
        response => {
          this.spinner = false;
             this._snackBar.open("Please try again, if problem persist speak to SpoorIT Administrator!", 'Close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
            });
             },
        () => {
        }
        );

    }


copyLink(value) {
    this.clipboard.copy(value);
      this._snackBar.open('Copied to clipboard!', 'Close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
        });

  }
  
}
